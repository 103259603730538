import React, { useState, useEffect } from "react";
import axios from 'axios';
import Particles from "react-particles-js";
import Socialicons from "../components/Socialicons";
import Layout from "../components/Layout";
import Typed from 'react-typed';

function Home() {
  const [information, setInformation] = useState("");
  const paramConfig = {
    particles: {
      number: {
        value: 300,
        density: {
          enable: false
        }
      },
      color: {
        value: "#ffffff"
      },
      opacity: {
        value: 1
      },
      size: {
        value: 1,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3
        }
      },
      line_linked: {
        enable: false
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out"
      }
    }
  };
  useEffect(() => {
    axios.get('/api/information')
      .then(response => {
        console.log("INFORMATOIONSSSS");
        console.log(information);
        setInformation(response.data);
      })
  }, [])

  if (!information.aboutContent) {
    return <div />
  }
  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles className="mi-home-particle" params={paramConfig} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Hi, I am <span className="color-theme">{information.name}</span>
                </h1>
                <Typed
                  strings={information.aboutContent}
                  typeSpeed={40}
                  backSpeed={40}
                />
                <Socialicons bordered />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
