import React, { useState, useEffect } from "react";
import axios from 'axios';
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";

import { toast } from 'react-toastify';

function Contact() {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailAddress, setEmailAddress] = useState([]);
  const [address, setAddress] = useState([]);
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const submitHandler = async (event) => {
    event.preventDefault();
    let message;
    let error;
    if (!formdata.name) {
      error = true;
      message = "Name is required";
    } else if (!formdata.email) {
      error = true;
      message = "Email is required";
    } else if (!formdata.subject) {
      error = true;
      message = "Subject is required";
    } else if (!formdata.message) {
      error = true;
      message = "Message is required";
    } else {
      error = false;
      message = "You message has been sent!";
    }
    handleAlerts(error, message);
  }
  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }
  const numberFormatter = (number) => {
    const phnNumber = number;
    return phnNumber;
  }

  const handleAlerts = (error, message) => {
    if (error && message) {
      toast.error(message, {});
    } else if (!error && message) {
      toast.success(message);
      console.log("alert form data");
      console.log(formdata);
      axios.post('/api/email', {
        name: formdata.name,
        email: formdata.email,
        subject: formdata.subject,
        message: formdata.message
      }).then(response => {
        console.log("response email");
        console.log(response);
      })
    } else {
      return null;
    }
  }

  useEffect(() => {
    axios.get('/api/contactinfo')
      .then(response => {
        console.log("response contact");
        console.log(response);
        setPhoneNumbers(response.data.phone);
        setEmailAddress(response.data.email);
        setAddress(response.data.address);
      })
  }, [])

  return (
    <Layout>
      <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Contact Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-contact-formwrapper">
                <h4>Get In Touch</h4>
                <form action="#" className="mi-form mi-contact-form" onSubmit={submitHandler}>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-name">Enter your name<span className="required">*</span></label>
                    <input onChange={handleChange} type="text" name="name" id="contact-form-name" value={formdata.name} />
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-email">Enter your email<span className="required">*</span></label>
                    <input onChange={handleChange} type="text" name="email" id="contact-form-email" value={formdata.email} />
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-subject">Enter your subject<span className="required">*</span></label>
                    <input onChange={handleChange} type="text" name="subject" id="contact-form-subject" value={formdata.subject} />
                  </div>
                  <div className="mi-form-field">
                    <label htmlFor="contact-form-message">Enter your Message<span className="required">*</span></label>
                    <textarea onChange={handleChange} name="message" id="contact-form-message" cols="30" rows="6" value={formdata.message}></textarea>
                  </div>
                  <div className="mi-form-field">
                    <button className="mi-button" type="submit">Send Mail</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-contact-info">
                {!phoneNumbers ? null : (
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.Phone />
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>Phone</h6>
                      {/*phoneNumbers.map(phoneNumber => (
                        <p key={phoneNumber}><a href={"tel:" + numberFormatter(phoneNumber)}>{phoneNumber}</a></p>
                      ))*/}
                      {phoneNumbers}
                    </div>
                  </div>
                )}
                {!emailAddress ? null : (
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.Mail />
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>Email</h6>
                      {/*emailAddress.map(email => (
                        <p key={email}><a href={`mailto:${email}`}>{email}</a></p>
                      ))*/}
                      {emailAddress}
                    </div>
                  </div>
                )}
                {!phoneNumbers ? null : (
                  <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.MapPin />
                    </span>
                    <div className="mi-contact-infoblock-content">
                      <h6>Address</h6>
                      <p>{address}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
