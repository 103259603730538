import React from "react";
import Header from "../components/Header";
import BackgroundLines from "../components/BackgroundLines";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Layout(props) {
  return (
    <div className="mi-wrapper">
      <BackgroundLines />
      <Header />
      {props.children}
      <ToastContainer />
    </div>
  );
}

export default Layout;
