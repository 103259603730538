import React, { useState, useEffect } from "react";
import axios from 'axios';
import Particles from "react-particles-js";
import Layout from "../components/Layout";
import ReactGlobe from 'react-globe';
import MoonLoader from "react-spinners/MoonLoader";
import ClipLoader from "react-spinners/ClipLoader";

//import 'tippy.js/dist/tippy.css';
import '../scss/pages/travels.css';
//import 'tippy.js/animations/scale.css';

import { toast } from 'react-toastify';
import { motion } from "framer-motion";

let colors = ["gold", "blue", "red", "green", "purple", "orange", "cyan"];

function getColor() {
    //return colors[Math.floor(Math.random() * colors.length)]
    var randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    return randomColor;
}

function calculatePop(value) {
    if (value > 1) {
        return value + "M";
    }
    else if (value < 1 && value >= .001) {
        let kval = value * 1000;
        return kval + "K";
    }
    else {
        let hval = value * 1000000;
        return hval;
    }
}

function addColors(markers) {
    let i;
    for (i = 0; i < markers.length; i++) {
        markers[i].color = getColor();
    }
    return markers;
}

function Travels() {
    const [showGlobe, setShowGlobe] = useState(false);
    const [markers, setMarkers] = useState([]);

    function onGlobeTextureLoaded() {
        setTimeout(() => { setShowGlobe(true); }, 1000);
    }

    const paramConfig = {
        particles: {
            number: {
                value: 300,
                density: {
                    enable: false
                }
            },
            color: {
                value: "#ffffff"
            },
            opacity: {
                value: 1
            },
            size: {
                value: 1,
                random: true,
                anim: {
                    speed: 4,
                    size_min: 0.3
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                random: true,
                speed: 1,
                direction: "top",
                out_mode: "out"
            }
        }
    };

    useEffect(() => {
        toast.info("Places I've been. Feel free to explore!");
        axios.get('/api/travels')
            .then(response => {
                let markers = addColors(response.data);
                setMarkers(markers);
            })
    }, [])

    return (
        <Layout>
            <div className="mi-travels-area mi-padding-section">
                <Particles className="mi-travels-particle" params={paramConfig} />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="mi-travels-content">
                                <div style={showGlobe ? { opacity: 0, display: "none", } : { opacity: 1, display: "block", opacity: 1, display: "block", margin: 0, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                    <MoonLoader
                                        size={150}
                                        color={"#037fff"}
                                        loading={true}
                                    />
                                </div>
                                <div style={showGlobe ? { opacity: 1 } : { opacity: 0 }}>
                                    <ReactGlobe
                                        markers={markers}
                                        globeBackgroundTexture={null}
                                        height="70vh"
                                        //onGetGlobe={onGetGlobe}
                                        onGlobeTextureLoaded={onGlobeTextureLoaded}
                                        options={{
                                            cameraAutoRotateSpeed: 0,
                                            enableGlobeGlow: false,
                                            globeGlowCoefficient: 0,
                                            globeGlowColor: 'black',
                                            globeGlowPower: 0,
                                            globeGlowRadiusScale: 0,
                                            pointLightColor: 'white',
                                            pointLightIntensity: 1,
                                            pointLightPositionRadiusScales: [2, 0, -1],
                                            enableMarkerTooltip: true,
                                            markerEnterAnimationDuration: 3000,
                                            markerEnterEasingFunction: ['Bounce', 'InOut'],
                                            markerExitAnimationDuration: 3000,
                                            markerExitEasingFunction: ['Cubic', 'Out'],
                                            /*markerTooltipRenderer: marker =>
                                                `${marker.city} | Population: ${calculatePop(marker.value)} | Dates Visited: ${marker.date} | My Story: ${marker.description}`,*/
                                            markerTooltipRenderer: marker =>
                                                `${marker.city} - ${marker.description}`,
                                            markerType: 'bar',
                                            markerRadiusScaleRange: [0.03, 1],
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Travels;
