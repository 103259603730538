import React from "react";

function Resume(props) {
  const { period, location, organization, details } = props.resumeData;
  console.log("DETAOLS");
  console.log(details);
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{period}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{location}</h5>
        <h6 className="mi-resume-company">{organization}</h6>
        <ul>
          {details.map(detail => (
            <li className="mi-resume-detail-item">{detail.detail}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Resume;
