import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import TrackVisibility from "react-on-screen";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from "../components/Smalltitle";
import Layout from "../components/Layout";
import Progress from "../components/Progress";
import Resume from "../components/Resume";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import Header from "./ResumePDF/Header";

function skillsAgg(skills) {
  let skillMap = skills.map((x) => x.title);
  let skillJoin = skillMap.join(", ");
  let finalSkill = skillJoin.substring(0, skillJoin.length - 2);
  return finalSkill;
}

function ResumePage() {
  const [skills, setSkills] = useState([]);
  const [workingExperience, setWorkingExperience] = useState([]);
  const [educationExperience, setEducationExperience] = useState([]);

  useEffect(() => {
    axios.get("/api/skills").then((response) => {
      setSkills(response.data);
    });
    axios.get("/api/experience").then((response) => {
      setWorkingExperience(response.data.workingExperience.reverse());
      setEducationExperience(response.data.educationExperience.reverse());
    });
  }, []);
  return (
    <Layout>
      <div className="mi-skills-area mi-section mi-padding-top">
        <div className="container">
          {educationExperience.length > 0 &&
          workingExperience.length > 0 &&
          skills.length > 0 ? (
            <div style={{ marginBottom: 30 }}>
              <PDFDownloadLink
                document={
                  <MyDoc
                    skills={skills}
                    educationExperience={educationExperience}
                    workingExperience={workingExperience}
                  />
                }
                fileName="Andrew Alexander.pdf"
                className="mi-button"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download Resume"
                }
              </PDFDownloadLink>
            </div>
          ) : null}
          <Sectiontitle title="Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills.map((skill) => (
                <TrackVisibility
                  once
                  className="col-lg-6 mt-30"
                  key={skill.title}
                >
                  <Progress title={skill.title} percentage={skill.value} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          <Smalltitle title="Work Experience" icon="briefcase" />
          <div className="mi-resume-wrapper">
            {workingExperience.map((workingExp) => (
              <Resume key={workingExp.id} resumeData={workingExp} />
            ))}
          </div>
          <div className="mt-30"></div>
          <Smalltitle title="Education" icon="book" />
          <div className="mi-resume-wrapper">
            {educationExperience.map((educationExp) => (
              <Resume key={educationExp.id} resumeData={educationExp} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ResumePage;

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    /*flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },*/
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
});

const MyDoc = (props) => (
  console.log("PROPS GO HERE"),
  console.log(props),
  (
    <Document>
      <Page style={styles.page}>
        <Header />
        <View style={styles.container}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
              marginTop: 10,
              display: "flex",
            }}
          >
            <View style={{ width: "100%" }}>
              <View style={{ marginBottom: 10 }}>
                <Text>Education</Text>
              </View>
              <View style={{ marginBottom: 10, height: 25 }}>
                <Text> </Text>
              </View>
              {props.educationExperience.map((educationExp) => (
                <View style={{ marginBottom: 10 }}>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ marginBottom: 10, width: "20%" }}>
                      <Text style={{ fontSize: 10 }}>
                        {educationExp.period}
                      </Text>
                    </View>
                    <View style={{ width: "80%" }}>
                      <View>
                        <Text style={{ fontSize: 16 }}>
                          {educationExp.location}
                        </Text>
                      </View>
                      <View>
                        <Text style={{ fontSize: 16 }}>
                          {educationExp.organization}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
              marginTop: 10,
              display: "flex",
            }}
          >
            <View style={{ width: "100%" }}>
              <View style={{ marginBottom: 10 }}>
                <Text>Experience</Text>
              </View>
              <View style={{ marginBottom: 10, height: 25 }}>
                <Text> </Text>
              </View>
              {props.workingExperience.map((workingExp) => (
                <View style={{ marginBottom: 10 }}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{ marginBottom: 10, marginTop: 10, width: "20%" }}
                    >
                      <Text style={{ fontSize: 10 }}>{workingExp.period}</Text>
                    </View>
                    <View style={{ width: "80%", marginBottom: 10 }}>
                      <View>
                        <Text style={{ fontSize: 16 }}>
                          {workingExp.location}
                        </Text>
                      </View>
                      <View>
                        <Text style={{ fontSize: 16, marginBottom: 10 }}>
                          {workingExp.organization}
                        </Text>
                      </View>
                      {workingExp.details.map((detail) => {
                        return (
                          <View
                            style={{ flexDirection: "row", marginBottom: 10 }}
                          >
                            <View style={{ marginLeft: 5 }}>
                              <Text style={{ fontSize: 16 }}>&bull; </Text>
                            </View>
                            <View>
                              <Text style={{ fontSize: 12 }}>
                                {detail.detail}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
              marginTop: 10,
              display: "flex",
            }}
          >
            <View style={{ width: "100%" }}>
              <View style={{ marginBottom: 10 }}>
                <Text>Skills/Technologies</Text>
              </View>
              <View style={{ width: "100%", justifyContent: "center" }}>
                <Text style={{ fontSize: 16 }}>{skillsAgg(props.skills)}</Text>
              </View>
              {/*props.skills.map((skill) => (
              <View style={{ flexDirection: "row", marginBottom: 5 }}>
                <View style={{ marginLeft: 5 }}>
                  <Text style={{ fontSize: 16 }}>&bull; </Text>
                </View>
                <View><Text style={{ fontSize: 8 }}>{skill.title}</Text></View>
              </View>
            ))*/}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
);
